<parcel
  [config]="config"
  [mountParcel]="mountRootParcel"
  [customProps]="parcelProps"
  wrapWith="h1"
  [onParcelMount]="parcelMounted"
  [appendTo]="target"
></parcel>
<div style="margin-top: 60px">
  <router-outlet></router-outlet>
</div>
<p-toast></p-toast>
<app-loading></app-loading>
