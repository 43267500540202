import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { goToPageLogin } from '@core/const';
import { MessageService } from 'primeng/api';

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 500 || err.status == 400) return of(new HttpResponse({ body: err.error, status: 0 }));
        if (err.status == 401) goToPageLogin();
        if (err.status == 504) this.messageService.add({ severity: 'error', summary: 'Error', detail: "Can't connect to server, please try again later!" });
        throw err;
      })
    );
  }
}
