import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationInitializeConfig } from '@core/application-initialize';
import { ApiModule } from '@core/api/be/api.module';
import { environment } from 'environments/environment';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { HandleErrorInterceptor } from '@core/interceptors/handle-error.interceptor';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { AuthenticationInterceptor } from '@core/interceptors/authentication.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AddWebDomaimPipe } from '@shared/pipes/add-web-domaim.pipe';
import { ParcelModule } from 'single-spa-angular/parcel';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
    ToastModule,
    LoadingComponent,
    AddWebDomaimPipe,
    ParcelModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializeConfig,
      deps: [HttpClient, CookieService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorInterceptor,
      multi: true,
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
