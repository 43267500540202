import { environment } from "environments/environment";
import { Eqelement, Eqzone } from "./api/be/models";

export enum ACTION_FROM_EDITION {
  BACK,
  SAVE
}

export const DEFAULT_VALUE = {
  COLOR: '#32546e',
  HEIGHT: 600, // cm
  UNIT: 100, // m -> cm
  DELAY_TIME: 100,
  TILE: 20
} as const;

export const ERROR_LIST = {
  NAME: `Vous devez renseigner un nom`,
  HEIGHT: `La hauteur doit est inférieur ou égale à celle de l'entrepôt`,
  MAX_HEIGHT: `max `,
  WIDTH: `La largeur doit est inférieur ou égale à celle de l'entrepôt`,
  LENGTH: `La longeur doit est inférieur ou égale à celle de l'entrepôt`,
  COMPANY: `Vous devez indiquer une societé`,
  WEIGHT: `Le poids doit être renseigné`,
  COUNTRY: `Le pays doit être rensigné`,
  CITY: `La ville doit être renseigné`,
  ADDRESS: `L'adresse doit être renseigné`,
  DESCRIPTION: `Le libellé doit être renseigné`,
  LEVEL: `Les niveaux doivent être renseigné`,
  BEGIN: `Renseigné le début du compartiments (0cm est le bord gauche du rack)`,
  END: `Renseigné la Fin du compartiments (Xcm est le bord droit du rack)`,
  THREE_CHARACTER: `3 Charactères obligatoire`,
  TWO_CHARACTER: `2 Charactères obligatoire`,
  ZONE_CODE: `Le code zone doit contenir deux caractères`,
  MAX_TILE: `100 cm maximum`,
  MIN_HEIGHT: `Merci de renseigner une hauteur minimum`,
  CODE_EXIST: `Ce code existe déjà, il doit être unique`,
  TYPE_EXIST: `Cette dénomination existe déjà`,
  DEFAULT_ZONE_CODE: `Le code 00 est déjà utilisé`
} as const;

export interface ITile {
  x: number;
  y: number;
  wall: string;
  road: string;
  door: string;
  type: string;
  zoneId?: string;
  elId?: string;
  background?: string;
  borderRack?: string;
  el?: Eqelement;
  zone?: Eqzone;
}

export interface ITileZone {
  x: number;
  y: number;
  xMax: number;
  yMax: number;
}

export enum COOKIE_KEY {
  KEY_TOKEN_COOKIE = 'SSO_COOKIE',
  KEY_TOKEN_REFRESH_COOKIE = 'SSO_COOKIE-X'
}

export function goToPageLogin() {
  // window.open(environment.ssoUrl + '?s_url=' + window.location.href, '_self');
}

function hashFnv32a(str: string) {
  /*jshint bitwise:false */
  var i, l
  var val = 0x811c9dc5

  for (i = 0, l = str.length; i < l; i++) {
    val ^= str.charCodeAt(i)
    val += (val << 1) + (val << 4) + (val << 7) + (val << 8) + (val << 24)
  }

  return (val >>> 0).toString(16).substring(-8)
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function randomString(lenString: number) {
  //define a variable consisting alphabets in small and capital letter
  var characters = 'abcdefghiklmnopqrstuvwxyz1234567890'

  //specify the length for the new string
  var result = ''

  //loop to select a new character in each iteration
  for (var i = 0; i < lenString; i++) {
    var num = Math.floor(Math.random() * characters.length)
    result += characters.substring(num, num + 1)
  }
  return result
}

function genKey(numberDigit: number) {
  if (numberDigit <= 9) {
    return ['', false]
  }
  let timestamp = Date.now().toString()
  let hashTime = hashFnv32a(timestamp)
  if (hashTime.length > numberDigit) {
    console.log('Hashtime key is: ', hashTime)
    console.log('Expect key digit is: ', numberDigit)
  }
  let result = (
    hashTime + randomString(numberDigit - hashTime.length)
  ).toString()

  if (result.length !== numberDigit) {
    return ['', false]
  }
  return [result, true]
}

export function GenKeyHook() {
  let code = '';
  const MAX_RETRY = 10
  const KEY_DIGIT = 10
  let keyOb = genKey(KEY_DIGIT)
  let count = 0
  while (!keyOb[1] && count < MAX_RETRY) {
    keyOb = genKey(KEY_DIGIT)
    sleep(10)
    count++
  }
  if (!keyOb[1]) {
    alert('Can not auto gen code!')
  } else {
    code = keyOb[0] as string;
  }
  return code;
}
