/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { EqlevelListResponseModel } from '../models/eqlevel-list-response-model';
import { EqlevelPaginationModelResponseModel } from '../models/eqlevel-pagination-model-response-model';
import { EqlevelResponseModel } from '../models/eqlevel-response-model';
import { LevelCreateModel } from '../models/level-create-model';
import { LevelUpdateModel } from '../models/level-update-model';

@Injectable({
  providedIn: 'root',
})
export class LevelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurLevelPageGet
   */
  static readonly EquateurLevelPageGetPath = '/equateur/Level/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelPageGet$Plain$Response(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelPageGetPath, 'get');
    if (params) {
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelPageGet$Plain(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqlevelPaginationModelResponseModel> {

    return this.equateurLevelPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelPaginationModelResponseModel>) => r.body as EqlevelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelPageGet$Json$Response(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelPageGetPath, 'get');
    if (params) {
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelPageGet$Json(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqlevelPaginationModelResponseModel> {

    return this.equateurLevelPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelPaginationModelResponseModel>) => r.body as EqlevelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelAllGet
   */
  static readonly EquateurLevelAllGetPath = '/equateur/Level/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByIdIdGet
   */
  static readonly EquateurLevelByIdIdGetPath = '/equateur/Level/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByNameNameGet
   */
  static readonly EquateurLevelByNameNameGetPath = '/equateur/Level/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByReferenceReferenceGet
   */
  static readonly EquateurLevelByReferenceReferenceGetPath = '/equateur/Level/ByReference/{reference}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByReferenceReferenceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByReferenceReferenceGet$Plain$Response(params: {
    reference: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByReferenceReferenceGetPath, 'get');
    if (params) {
      rb.path('reference', params.reference, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByReferenceReferenceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByReferenceReferenceGet$Plain(params: {
    reference: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByReferenceReferenceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByReferenceReferenceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByReferenceReferenceGet$Json$Response(params: {
    reference: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByReferenceReferenceGetPath, 'get');
    if (params) {
      rb.path('reference', params.reference, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByReferenceReferenceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByReferenceReferenceGet$Json(params: {
    reference: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByReferenceReferenceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByCodeCodeGet
   */
  static readonly EquateurLevelByCodeCodeGetPath = '/equateur/Level/ByCode/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByCodeCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByCodeCodeGet$Plain$Response(params: {
    code: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByCodeCodeGetPath, 'get');
    if (params) {
      rb.path('code', params.code, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByCodeCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByCodeCodeGet$Plain(params: {
    code: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByCodeCodeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByCodeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByCodeCodeGet$Json$Response(params: {
    code: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByCodeCodeGetPath, 'get');
    if (params) {
      rb.path('code', params.code, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByCodeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByCodeCodeGet$Json(params: {
    code: string;
    context?: HttpContext
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelByCodeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByElementIdGet
   */
  static readonly EquateurLevelByElementIdGetPath = '/equateur/Level/ByElement/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByElementIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByElementIdGet$Plain$Response(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByElementIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByElementIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByElementIdGet$Plain(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqlevelPaginationModelResponseModel> {

    return this.equateurLevelByElementIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelPaginationModelResponseModel>) => r.body as EqlevelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByElementIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByElementIdGet$Json$Response(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByElementIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByElementIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByElementIdGet$Json(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqlevelPaginationModelResponseModel> {

    return this.equateurLevelByElementIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelPaginationModelResponseModel>) => r.body as EqlevelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByElementsPost
   */
  static readonly EquateurLevelByElementsPostPath = '/equateur/Level/ByElements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByElementsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelByElementsPost$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByElementsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByElementsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelByElementsPost$Plain(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelByElementsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByElementsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelByElementsPost$Json$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByElementsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByElementsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelByElementsPost$Json(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelByElementsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelByRackLevelAndElementElementIdRackLevelIdGet
   */
  static readonly EquateurLevelByRackLevelAndElementElementIdRackLevelIdGetPath = '/equateur/Level/ByRackLevelAndElement/{elementId}/{rackLevelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Plain$Response(params: {
    elementId: string;
    rackLevelId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByRackLevelAndElementElementIdRackLevelIdGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
      rb.path('rackLevelId', params.rackLevelId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Plain(params: {
    elementId: string;
    rackLevelId: string;
    context?: HttpContext
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Json$Response(params: {
    elementId: string;
    rackLevelId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelByRackLevelAndElementElementIdRackLevelIdGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
      rb.path('rackLevelId', params.rackLevelId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Json(params: {
    elementId: string;
    rackLevelId: string;
    context?: HttpContext
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelByRackLevelAndElementElementIdRackLevelIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet
   */
  static readonly EquateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGetPath = '/equateur/Level/IsExistLevelInElement/{elementId}/{rackLevelId}/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Plain$Response(params: {
    elementId: string;
    rackLevelId: string;
    code: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
      rb.path('rackLevelId', params.rackLevelId, {"style":"simple"});
      rb.path('code', params.code, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Plain(params: {
    elementId: string;
    rackLevelId: string;
    code: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Json$Response(params: {
    elementId: string;
    rackLevelId: string;
    code: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
      rb.path('rackLevelId', params.rackLevelId, {"style":"simple"});
      rb.path('code', params.code, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Json(params: {
    elementId: string;
    rackLevelId: string;
    code: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurLevelIsExistLevelInElementElementIdRackLevelIdCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelCreatePost
   */
  static readonly EquateurLevelCreatePostPath = '/equateur/Level/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: LevelCreateModel
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelCreatePost$Plain(params?: {
    context?: HttpContext
    body?: LevelCreateModel
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: LevelCreateModel
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelCreatePost$Json(params?: {
    context?: HttpContext
    body?: LevelCreateModel
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelUpdateIdPut
   */
  static readonly EquateurLevelUpdateIdPutPath = '/equateur/Level/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: LevelUpdateModel
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: LevelUpdateModel
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: LevelUpdateModel
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: LevelUpdateModel
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelDeleteIdDelete
   */
  static readonly EquateurLevelDeleteIdDeletePath = '/equateur/Level/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqlevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqlevelResponseModel> {

    return this.equateurLevelDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelResponseModel>) => r.body as EqlevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurLevelDeleteByElementIdDelete
   */
  static readonly EquateurLevelDeleteByElementIdDeletePath = '/equateur/Level/DeleteByElement/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelDeleteByElementIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteByElementIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelDeleteByElementIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelDeleteByElementIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteByElementIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelDeleteByElementIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurLevelDeleteByElementIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteByElementIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqlevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, LevelService.EquateurLevelDeleteByElementIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqlevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurLevelDeleteByElementIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurLevelDeleteByElementIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqlevelListResponseModel> {

    return this.equateurLevelDeleteByElementIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqlevelListResponseModel>) => r.body as EqlevelListResponseModel)
    );
  }

}
