import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { ICountry, StoreService } from "./services/store.service";
import { COOKIE_KEY, goToPageLogin } from "./const";

export function ApplicationInitializeConfig(http: HttpClient, cookieService: CookieService) {
  return async () => {
    try {
      const token = cookieService.get(COOKIE_KEY.KEY_TOKEN_COOKIE);
      const refreshToken = cookieService.get(COOKIE_KEY.KEY_TOKEN_REFRESH_COOKIE);
      if (token && refreshToken) {
        StoreService.wmsToken = token;
        StoreService.refreshToken = refreshToken;
        await getInitData(http);
      }
      else await goToPageLogin();
    }
    catch (err) { console.log(err); }
  }
}

function getInitData(http: HttpClient) {
  const data = {
    "DE": "Allemagne",
    "AT": "Autriche",
    "BE": "Belgique",
    "DK": "Danemarque",
    "ES": "Espagne",
    "FR": "France",
    "GR": "Grece",
    "HU": "Honngrie",
    "IE": "Irlande",
    "IT": "Italie",
    "LU": "Luxembourg",
    "NL": "Pays-bas",
    "PL": "Pologne",
    "PT": "Portugal",
    "GB": "Royaume-uni",
    "CH": "Suisse"
  }
  return handleJsonCountry(data)
  // const url = `${window.location.origin}/assets/data/country.json`;
  // return new Promise((resolve, reject) => {
  //   http.get(url).subscribe((res) => {
  //     if (res != null) {
  //       StoreService.countries = handleJsonCountry(res);
  //       resolve(null);
  //     }
  //     else reject(null);
  //   });
  // });
}

function handleJsonCountry(res: any) {
  let keys = Object.keys(res);
  let countries: ICountry[] = [];
  for (let index = 0; index < keys.length; index++) {
    countries.push({ code: keys[index], name: res[keys[index]] })
  }
  return countries;
}
