/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ElementTypeEnumPaginationModelResponseModel } from '../models/element-type-enum-pagination-model-response-model';
import { EqelementTypeResponseModel } from '../models/eqelement-type-response-model';
import { PathUrlFileResponsiveModelResponseModel } from '../models/path-url-file-responsive-model-response-model';
import { ZoneTypeEnumPaginationModelResponseModel } from '../models/zone-type-enum-pagination-model-response-model';
import { ZoneTypeEnumResponseModel } from '../models/zone-type-enum-response-model';

@Injectable({
  providedIn: 'root',
})
export class AppEnumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurEnumTypeElementPageGet
   */
  static readonly EquateurEnumTypeElementPageGetPath = '/equateur/enum/type/element/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeElementPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementPageGet$Plain$Response(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ElementTypeEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeElementPageGetPath, 'get');
    if (params) {
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElementTypeEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeElementPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementPageGet$Plain(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<ElementTypeEnumPaginationModelResponseModel> {

    return this.equateurEnumTypeElementPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElementTypeEnumPaginationModelResponseModel>) => r.body as ElementTypeEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeElementPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementPageGet$Json$Response(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ElementTypeEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeElementPageGetPath, 'get');
    if (params) {
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElementTypeEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeElementPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementPageGet$Json(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<ElementTypeEnumPaginationModelResponseModel> {

    return this.equateurEnumTypeElementPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElementTypeEnumPaginationModelResponseModel>) => r.body as ElementTypeEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurEnumTypeElementBynameNameGet
   */
  static readonly EquateurEnumTypeElementBynameNameGetPath = '/equateur/enum/type/element/byname/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeElementBynameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementBynameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeElementBynameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeElementBynameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementBynameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurEnumTypeElementBynameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeElementBynameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementBynameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeElementBynameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeElementBynameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementBynameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurEnumTypeElementBynameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurEnumTypeElementByvalueValueGet
   */
  static readonly EquateurEnumTypeElementByvalueValueGetPath = '/equateur/enum/type/element/byvalue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeElementByvalueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementByvalueValueGet$Plain$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeElementByvalueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeElementByvalueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementByvalueValueGet$Plain(params: {
    value: string;
    context?: HttpContext
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurEnumTypeElementByvalueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeElementByvalueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementByvalueValueGet$Json$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeElementByvalueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeElementByvalueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeElementByvalueValueGet$Json(params: {
    value: string;
    context?: HttpContext
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurEnumTypeElementByvalueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurEnumTypeZonePageGet
   */
  static readonly EquateurEnumTypeZonePageGetPath = '/equateur/enum/type/zone/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeZonePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZonePageGet$Plain$Response(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ZoneTypeEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeZonePageGetPath, 'get');
    if (params) {
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZoneTypeEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeZonePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZonePageGet$Plain(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<ZoneTypeEnumPaginationModelResponseModel> {

    return this.equateurEnumTypeZonePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ZoneTypeEnumPaginationModelResponseModel>) => r.body as ZoneTypeEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeZonePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZonePageGet$Json$Response(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ZoneTypeEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeZonePageGetPath, 'get');
    if (params) {
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZoneTypeEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeZonePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZonePageGet$Json(params?: {
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<ZoneTypeEnumPaginationModelResponseModel> {

    return this.equateurEnumTypeZonePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ZoneTypeEnumPaginationModelResponseModel>) => r.body as ZoneTypeEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurEnumTypeZoneBynameNameGet
   */
  static readonly EquateurEnumTypeZoneBynameNameGetPath = '/equateur/enum/type/zone/byname/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeZoneBynameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneBynameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ZoneTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeZoneBynameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZoneTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeZoneBynameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneBynameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ZoneTypeEnumResponseModel> {

    return this.equateurEnumTypeZoneBynameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ZoneTypeEnumResponseModel>) => r.body as ZoneTypeEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeZoneBynameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneBynameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ZoneTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeZoneBynameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZoneTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeZoneBynameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneBynameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ZoneTypeEnumResponseModel> {

    return this.equateurEnumTypeZoneBynameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ZoneTypeEnumResponseModel>) => r.body as ZoneTypeEnumResponseModel)
    );
  }

  /**
   * Path part for operation equateurEnumTypeZoneByvalueValueGet
   */
  static readonly EquateurEnumTypeZoneByvalueValueGetPath = '/equateur/enum/type/zone/byvalue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeZoneByvalueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneByvalueValueGet$Plain$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ZoneTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeZoneByvalueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZoneTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeZoneByvalueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneByvalueValueGet$Plain(params: {
    value: string;
    context?: HttpContext
  }
): Observable<ZoneTypeEnumResponseModel> {

    return this.equateurEnumTypeZoneByvalueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ZoneTypeEnumResponseModel>) => r.body as ZoneTypeEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurEnumTypeZoneByvalueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneByvalueValueGet$Json$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ZoneTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurEnumTypeZoneByvalueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZoneTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurEnumTypeZoneByvalueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurEnumTypeZoneByvalueValueGet$Json(params: {
    value: string;
    context?: HttpContext
  }
): Observable<ZoneTypeEnumResponseModel> {

    return this.equateurEnumTypeZoneByvalueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ZoneTypeEnumResponseModel>) => r.body as ZoneTypeEnumResponseModel)
    );
  }

  /**
   * Path part for operation equateurFileUrlPathPost
   */
  static readonly EquateurFileUrlPathPostPath = '/equateur/file/url/{path}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurFileUrlPathPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurFileUrlPathPost$Plain$Response(params: {
    path: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<PathUrlFileResponsiveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurFileUrlPathPostPath, 'post');
    if (params) {
      rb.path('path', params.path, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PathUrlFileResponsiveModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurFileUrlPathPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurFileUrlPathPost$Plain(params: {
    path: string;
    context?: HttpContext
    body?: string
  }
): Observable<PathUrlFileResponsiveModelResponseModel> {

    return this.equateurFileUrlPathPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PathUrlFileResponsiveModelResponseModel>) => r.body as PathUrlFileResponsiveModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurFileUrlPathPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurFileUrlPathPost$Json$Response(params: {
    path: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<PathUrlFileResponsiveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.EquateurFileUrlPathPostPath, 'post');
    if (params) {
      rb.path('path', params.path, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PathUrlFileResponsiveModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurFileUrlPathPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurFileUrlPathPost$Json(params: {
    path: string;
    context?: HttpContext
    body?: string
  }
): Observable<PathUrlFileResponsiveModelResponseModel> {

    return this.equateurFileUrlPathPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PathUrlFileResponsiveModelResponseModel>) => r.body as PathUrlFileResponsiveModelResponseModel)
    );
  }

}
