/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AllOptionsResponseIListResponseModel } from '../models/all-options-response-i-list-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { EqwarehouseCompanyListResponseModel } from '../models/eqwarehouse-company-list-response-model';
import { EqwarehouseListResponseModel } from '../models/eqwarehouse-list-response-model';
import { EqwarehousePaginationModelResponseModel } from '../models/eqwarehouse-pagination-model-response-model';
import { EqwarehouseResponseModel } from '../models/eqwarehouse-response-model';
import { StringListResponseModel } from '../models/string-list-response-model';
import { WarehouseCreateModel } from '../models/warehouse-create-model';
import { WarehouseFilterModel } from '../models/warehouse-filter-model';
import { WarehouseUpdateModel } from '../models/warehouse-update-model';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurWarehousePagePLGet
   */
  static readonly EquateurWarehousePagePLGetPath = '/equateur/Warehouse/Page/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehousePagePLGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePagePLGet$Plain$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehousePagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehousePagePLGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePagePLGet$Plain(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehousePagePLGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehousePagePLGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePagePLGet$Json$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehousePagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehousePagePLGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePagePLGet$Json(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehousePagePLGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehousePageWithUserPLGet
   */
  static readonly EquateurWarehousePageWithUserPLGetPath = '/equateur/Warehouse/PageWithUser/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehousePageWithUserPLGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePageWithUserPLGet$Plain$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehousePageWithUserPLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehousePageWithUserPLGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePageWithUserPLGet$Plain(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehousePageWithUserPLGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehousePageWithUserPLGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePageWithUserPLGet$Json$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehousePageWithUserPLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehousePageWithUserPLGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehousePageWithUserPLGet$Json(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehousePageWithUserPLGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseAllGet
   */
  static readonly EquateurWarehouseAllGetPath = '/equateur/Warehouse/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehouseAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehouseAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseAllOptionsGet
   */
  static readonly EquateurWarehouseAllOptionsGetPath = '/equateur/Warehouse/AllOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseAllOptionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllOptionsGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AllOptionsResponseIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseAllOptionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllOptionsResponseIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseAllOptionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllOptionsGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<AllOptionsResponseIListResponseModel> {

    return this.equateurWarehouseAllOptionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AllOptionsResponseIListResponseModel>) => r.body as AllOptionsResponseIListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseAllOptionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllOptionsGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AllOptionsResponseIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseAllOptionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllOptionsResponseIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseAllOptionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseAllOptionsGet$Json(params?: {
    context?: HttpContext
  }
): Observable<AllOptionsResponseIListResponseModel> {

    return this.equateurWarehouseAllOptionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AllOptionsResponseIListResponseModel>) => r.body as AllOptionsResponseIListResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseSearchByNameNamePLGet
   */
  static readonly EquateurWarehouseSearchByNameNamePLGetPath = '/equateur/Warehouse/SearchByName/{name}/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseSearchByNameNamePLGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseSearchByNameNamePLGet$Plain$Response(params: {
    name: string;
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseSearchByNameNamePLGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseSearchByNameNamePLGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseSearchByNameNamePLGet$Plain(params: {
    name: string;
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehouseSearchByNameNamePLGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseSearchByNameNamePLGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseSearchByNameNamePLGet$Json$Response(params: {
    name: string;
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseSearchByNameNamePLGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseSearchByNameNamePLGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseSearchByNameNamePLGet$Json(params: {
    name: string;
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehouseSearchByNameNamePLGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseFilterPLPost
   */
  static readonly EquateurWarehouseFilterPLPostPath = '/equateur/Warehouse/Filter/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseFilterPLPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseFilterPLPost$Plain$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
    body?: WarehouseFilterModel
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseFilterPLPostPath, 'post');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseFilterPLPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseFilterPLPost$Plain(params: {
    'p': number;
    'l': number;
    context?: HttpContext
    body?: WarehouseFilterModel
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehouseFilterPLPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseFilterPLPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseFilterPLPost$Json$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
    body?: WarehouseFilterModel
  }
): Observable<StrictHttpResponse<EqwarehousePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseFilterPLPostPath, 'post');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehousePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseFilterPLPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseFilterPLPost$Json(params: {
    'p': number;
    'l': number;
    context?: HttpContext
    body?: WarehouseFilterModel
  }
): Observable<EqwarehousePaginationModelResponseModel> {

    return this.equateurWarehouseFilterPLPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehousePaginationModelResponseModel>) => r.body as EqwarehousePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseByIdIdGet
   */
  static readonly EquateurWarehouseByIdIdGetPath = '/equateur/Warehouse/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseByNameNameGet
   */
  static readonly EquateurWarehouseByNameNameGetPath = '/equateur/Warehouse/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseByCityNameGet
   */
  static readonly EquateurWarehouseByCityNameGetPath = '/equateur/Warehouse/ByCity/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByCityNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCityNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByCityNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByCityNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCityNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqwarehouseListResponseModel> {

    return this.equateurWarehouseByCityNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseListResponseModel>) => r.body as EqwarehouseListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByCityNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCityNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByCityNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByCityNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCityNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqwarehouseListResponseModel> {

    return this.equateurWarehouseByCityNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseListResponseModel>) => r.body as EqwarehouseListResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseByCountryNameGet
   */
  static readonly EquateurWarehouseByCountryNameGetPath = '/equateur/Warehouse/ByCountry/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByCountryNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCountryNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByCountryNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByCountryNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCountryNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqwarehouseListResponseModel> {

    return this.equateurWarehouseByCountryNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseListResponseModel>) => r.body as EqwarehouseListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseByCountryNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCountryNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseByCountryNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseByCountryNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurWarehouseByCountryNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqwarehouseListResponseModel> {

    return this.equateurWarehouseByCountryNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseListResponseModel>) => r.body as EqwarehouseListResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseCreatePost
   */
  static readonly EquateurWarehouseCreatePostPath = '/equateur/Warehouse/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: WarehouseCreateModel
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseCreatePost$Plain(params?: {
    context?: HttpContext
    body?: WarehouseCreateModel
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: WarehouseCreateModel
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseCreatePost$Json(params?: {
    context?: HttpContext
    body?: WarehouseCreateModel
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseUpdateIdPut
   */
  static readonly EquateurWarehouseUpdateIdPutPath = '/equateur/Warehouse/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: WarehouseUpdateModel
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: WarehouseUpdateModel
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: WarehouseUpdateModel
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: WarehouseUpdateModel
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseDeleteIdDelete
   */
  static readonly EquateurWarehouseDeleteIdDeletePath = '/equateur/Warehouse/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqwarehouseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqwarehouseResponseModel> {

    return this.equateurWarehouseDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseResponseModel>) => r.body as EqwarehouseResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseAddCompaniesIdPost
   */
  static readonly EquateurWarehouseAddCompaniesIdPostPath = '/equateur/Warehouse/AddCompanies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseAddCompaniesIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseAddCompaniesIdPost$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqwarehouseCompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseAddCompaniesIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseCompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseAddCompaniesIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseAddCompaniesIdPost$Plain(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqwarehouseCompanyListResponseModel> {

    return this.equateurWarehouseAddCompaniesIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseCompanyListResponseModel>) => r.body as EqwarehouseCompanyListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseAddCompaniesIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseAddCompaniesIdPost$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqwarehouseCompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseAddCompaniesIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseCompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseAddCompaniesIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseAddCompaniesIdPost$Json(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqwarehouseCompanyListResponseModel> {

    return this.equateurWarehouseAddCompaniesIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseCompanyListResponseModel>) => r.body as EqwarehouseCompanyListResponseModel)
    );
  }

  /**
   * Path part for operation equateurWarehouseRemoveCompaniesIdDelete
   */
  static readonly EquateurWarehouseRemoveCompaniesIdDeletePath = '/equateur/Warehouse/RemoveCompanies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseRemoveCompaniesIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseRemoveCompaniesIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<StringListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseRemoveCompaniesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseRemoveCompaniesIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseRemoveCompaniesIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StringListResponseModel> {

    return this.equateurWarehouseRemoveCompaniesIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringListResponseModel>) => r.body as StringListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurWarehouseRemoveCompaniesIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseRemoveCompaniesIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<StringListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.EquateurWarehouseRemoveCompaniesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurWarehouseRemoveCompaniesIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurWarehouseRemoveCompaniesIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StringListResponseModel> {

    return this.equateurWarehouseRemoveCompaniesIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringListResponseModel>) => r.body as StringListResponseModel)
    );
  }

}
