import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'addWebDomaim',
  standalone: true
})
export class AddWebDomaimPipe implements PipeTransform {

  transform(path: string): string {
    return environment.webUrl + path;
  }

}
