/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EqrackLevelListResponseModel } from '../models/eqrack-level-list-response-model';
import { EqrackLevelResponseModel } from '../models/eqrack-level-response-model';
import { RackLevelCreateModel } from '../models/rack-level-create-model';
import { RackLevelDeleteModel } from '../models/rack-level-delete-model';
import { RackLevelUpdateModel } from '../models/rack-level-update-model';

@Injectable({
  providedIn: 'root',
})
export class RackLevelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurRackLevelAllGet
   */
  static readonly EquateurRackLevelAllGetPath = '/equateur/RackLevel/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqrackLevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqrackLevelListResponseModel> {

    return this.equateurRackLevelAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelListResponseModel>) => r.body as EqrackLevelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqrackLevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqrackLevelListResponseModel> {

    return this.equateurRackLevelAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelListResponseModel>) => r.body as EqrackLevelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurRackLevelByIdIdGet
   */
  static readonly EquateurRackLevelByIdIdGetPath = '/equateur/RackLevel/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurRackLevelByElementIdIdGet
   */
  static readonly EquateurRackLevelByElementIdIdGetPath = '/equateur/RackLevel/ByElementId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelByElementIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByElementIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqrackLevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelByElementIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelByElementIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByElementIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqrackLevelListResponseModel> {

    return this.equateurRackLevelByElementIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelListResponseModel>) => r.body as EqrackLevelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelByElementIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByElementIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqrackLevelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelByElementIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelByElementIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurRackLevelByElementIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqrackLevelListResponseModel> {

    return this.equateurRackLevelByElementIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelListResponseModel>) => r.body as EqrackLevelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurRackLevelCreatePost
   */
  static readonly EquateurRackLevelCreatePostPath = '/equateur/RackLevel/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: RackLevelCreateModel
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelCreatePost$Plain(params?: {
    context?: HttpContext
    body?: RackLevelCreateModel
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: RackLevelCreateModel
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelCreatePost$Json(params?: {
    context?: HttpContext
    body?: RackLevelCreateModel
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurRackLevelUpdateIdPut
   */
  static readonly EquateurRackLevelUpdateIdPutPath = '/equateur/RackLevel/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelUpdateModel
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelUpdateModel
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelUpdateModel
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelUpdateModel
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * Path part for operation equateurRackLevelDeleteIdDelete
   */
  static readonly EquateurRackLevelDeleteIdDeletePath = '/equateur/RackLevel/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelDeleteModel
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelDeleteModel
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurRackLevelDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelDeleteModel
  }
): Observable<StrictHttpResponse<EqrackLevelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RackLevelService.EquateurRackLevelDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqrackLevelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurRackLevelDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurRackLevelDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: RackLevelDeleteModel
  }
): Observable<EqrackLevelResponseModel> {

    return this.equateurRackLevelDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqrackLevelResponseModel>) => r.body as EqrackLevelResponseModel)
    );
  }

}
