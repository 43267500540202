/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AppEnumService } from './services/app-enum.service';
import { CompanyService } from './services/company.service';
import { DatabaseService } from './services/database.service';
import { ElementService } from './services/element.service';
import { ElementTypeService } from './services/element-type.service';
import { LevelService } from './services/level.service';
import { RackLevelService } from './services/rack-level.service';
import { UserService } from './services/user.service';
import { WarehouseService } from './services/warehouse.service';
import { ZoneService } from './services/zone.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AppEnumService,
    CompanyService,
    DatabaseService,
    ElementService,
    ElementTypeService,
    LevelService,
    RackLevelService,
    UserService,
    WarehouseService,
    ZoneService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
