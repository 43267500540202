import { Component } from '@angular/core';
import { mountRootParcel } from 'single-spa';
import { StoreService } from '@core/services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'angular-app';
  mountRootParcel = mountRootParcel;
  parcelProps = { app: 'angular' };
  target = document.body;

  getUserName() {
    return StoreService.user ? StoreService.user.nom : '';
  }

  async config() {
    return (window as any).System.import('@equateur/nav-bar');
  }
  constructor() {}

  parcelMounted(): void {
    console.log('React parcel mounted');
  }
}
