/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { EqzoneListResponseModel } from '../models/eqzone-list-response-model';
import { EqzonePaginationModelResponseModel } from '../models/eqzone-pagination-model-response-model';
import { EqzoneResponseModel } from '../models/eqzone-response-model';
import { GuidListResponseModel } from '../models/guid-list-response-model';
import { ZoneCreateModel } from '../models/zone-create-model';
import { ZoneUpdateDataModel } from '../models/zone-update-data-model';
import { ZoneUpdateModel } from '../models/zone-update-model';

@Injectable({
  providedIn: 'root',
})
export class ZoneService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurZonePagePLGet
   */
  static readonly EquateurZonePagePLGetPath = '/equateur/Zone/Page/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZonePagePLGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZonePagePLGet$Plain$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzonePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZonePagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzonePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZonePagePLGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZonePagePLGet$Plain(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqzonePaginationModelResponseModel> {

    return this.equateurZonePagePLGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzonePaginationModelResponseModel>) => r.body as EqzonePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZonePagePLGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZonePagePLGet$Json$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzonePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZonePagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzonePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZonePagePLGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZonePagePLGet$Json(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqzonePaginationModelResponseModel> {

    return this.equateurZonePagePLGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzonePaginationModelResponseModel>) => r.body as EqzonePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneAllGet
   */
  static readonly EquateurZoneAllGetPath = '/equateur/Zone/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzonePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzonePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqzonePaginationModelResponseModel> {

    return this.equateurZoneAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzonePaginationModelResponseModel>) => r.body as EqzonePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzonePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzonePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqzonePaginationModelResponseModel> {

    return this.equateurZoneAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzonePaginationModelResponseModel>) => r.body as EqzonePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneByIdIdGet
   */
  static readonly EquateurZoneByIdIdGetPath = '/equateur/Zone/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneByNameNameGet
   */
  static readonly EquateurZoneByNameNameGetPath = '/equateur/Zone/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneByCityNameGet
   */
  static readonly EquateurZoneByCityNameGetPath = '/equateur/Zone/ByCity/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByCityNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCityNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByCityNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByCityNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCityNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqzoneListResponseModel> {

    return this.equateurZoneByCityNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneListResponseModel>) => r.body as EqzoneListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByCityNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCityNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByCityNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByCityNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCityNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqzoneListResponseModel> {

    return this.equateurZoneByCityNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneListResponseModel>) => r.body as EqzoneListResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneByCountryNameGet
   */
  static readonly EquateurZoneByCountryNameGetPath = '/equateur/Zone/ByCountry/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByCountryNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCountryNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByCountryNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByCountryNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCountryNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqzoneListResponseModel> {

    return this.equateurZoneByCountryNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneListResponseModel>) => r.body as EqzoneListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByCountryNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCountryNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByCountryNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByCountryNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByCountryNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqzoneListResponseModel> {

    return this.equateurZoneByCountryNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneListResponseModel>) => r.body as EqzoneListResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneByWarehouseIdGet
   */
  static readonly EquateurZoneByWarehouseIdGetPath = '/equateur/Zone/ByWarehouse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByWarehouseIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByWarehouseIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqzoneListResponseModel> {

    return this.equateurZoneByWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneListResponseModel>) => r.body as EqzoneListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneByWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByWarehouseIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneByWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneByWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneByWarehouseIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqzoneListResponseModel> {

    return this.equateurZoneByWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneListResponseModel>) => r.body as EqzoneListResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneCreatePost
   */
  static readonly EquateurZoneCreatePostPath = '/equateur/Zone/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: ZoneCreateModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneCreatePost$Plain(params?: {
    context?: HttpContext
    body?: ZoneCreateModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: ZoneCreateModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneCreatePost$Json(params?: {
    context?: HttpContext
    body?: ZoneCreateModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneUpdateIdPut
   */
  static readonly EquateurZoneUpdateIdPutPath = '/equateur/Zone/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneUpdateDataIdPut
   */
  static readonly EquateurZoneUpdateDataIdPutPath = '/equateur/Zone/UpdateData/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneUpdateDataIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateDataIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateDataModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneUpdateDataIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneUpdateDataIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateDataIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateDataModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneUpdateDataIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneUpdateDataIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateDataIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateDataModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneUpdateDataIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneUpdateDataIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneUpdateDataIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ZoneUpdateDataModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneUpdateDataIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneDeleteIdDelete
   */
  static readonly EquateurZoneDeleteIdDeletePath = '/equateur/Zone/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneSetCompanyZoneIdPut
   */
  static readonly EquateurZoneSetCompanyZoneIdPutPath = '/equateur/Zone/SetCompany/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneSetCompanyZoneIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneSetCompanyZoneIdPut$Plain$Response(params: {
    zoneId: string;
    companyId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneSetCompanyZoneIdPutPath, 'put');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneSetCompanyZoneIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneSetCompanyZoneIdPut$Plain(params: {
    zoneId: string;
    companyId?: string;
    context?: HttpContext
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneSetCompanyZoneIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneSetCompanyZoneIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneSetCompanyZoneIdPut$Json$Response(params: {
    zoneId: string;
    companyId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqzoneResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneSetCompanyZoneIdPutPath, 'put');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqzoneResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneSetCompanyZoneIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneSetCompanyZoneIdPut$Json(params: {
    zoneId: string;
    companyId?: string;
    context?: HttpContext
  }
): Observable<EqzoneResponseModel> {

    return this.equateurZoneSetCompanyZoneIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqzoneResponseModel>) => r.body as EqzoneResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneAddElementsZoneIdPost
   */
  static readonly EquateurZoneAddElementsZoneIdPostPath = '/equateur/Zone/AddElements/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneAddElementsZoneIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneAddElementsZoneIdPost$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneAddElementsZoneIdPostPath, 'post');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneAddElementsZoneIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneAddElementsZoneIdPost$Plain(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<GuidListResponseModel> {

    return this.equateurZoneAddElementsZoneIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneAddElementsZoneIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneAddElementsZoneIdPost$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneAddElementsZoneIdPostPath, 'post');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneAddElementsZoneIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurZoneAddElementsZoneIdPost$Json(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<GuidListResponseModel> {

    return this.equateurZoneAddElementsZoneIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * Path part for operation equateurZoneIsExistCodeInZoneWarehouseIdCodeGet
   */
  static readonly EquateurZoneIsExistCodeInZoneWarehouseIdCodeGetPath = '/equateur/Zone/IsExistCodeInZone/{warehouseId}/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Plain$Response(params: {
    warehouseId: string;
    code: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneIsExistCodeInZoneWarehouseIdCodeGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
      rb.path('code', params.code, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Plain(params: {
    warehouseId: string;
    code: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Json$Response(params: {
    warehouseId: string;
    code: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.EquateurZoneIsExistCodeInZoneWarehouseIdCodeGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
      rb.path('code', params.code, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Json(params: {
    warehouseId: string;
    code: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurZoneIsExistCodeInZoneWarehouseIdCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
