/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientResponsiveModelListResponseModel } from '../models/client-responsive-model-list-response-model';
import { CompanyCreateModel } from '../models/company-create-model';
import { CompanyResponsiveModelListResponseModel } from '../models/company-responsive-model-list-response-model';
import { CompanyUpdateModel } from '../models/company-update-model';
import { CreateCompanyWarehousesRequest } from '../models/create-company-warehouses-request';
import { CreateCompanyWarehousesResponseResponseModel } from '../models/create-company-warehouses-response-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { EqcompanyListResponseModel } from '../models/eqcompany-list-response-model';
import { EqcompanyPaginationModelResponseModel } from '../models/eqcompany-pagination-model-response-model';
import { EqcompanyResponseModel } from '../models/eqcompany-response-model';
import { EqwarehouseCompanyListResponseModel } from '../models/eqwarehouse-company-list-response-model';
import { GetClientListCreateModel } from '../models/get-client-list-create-model';
import { ReferenceResponsiveModelListResponseModel } from '../models/reference-responsive-model-list-response-model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurCompanyPagePLGet
   */
  static readonly EquateurCompanyPagePLGetPath = '/equateur/Company/Page/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyPagePLGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyPagePLGet$Plain$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyPagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyPagePLGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyPagePLGet$Plain(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqcompanyPaginationModelResponseModel> {

    return this.equateurCompanyPagePLGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyPaginationModelResponseModel>) => r.body as EqcompanyPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyPagePLGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyPagePLGet$Json$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyPagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyPagePLGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyPagePLGet$Json(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqcompanyPaginationModelResponseModel> {

    return this.equateurCompanyPagePLGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyPaginationModelResponseModel>) => r.body as EqcompanyPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyAllGet
   */
  static readonly EquateurCompanyAllGetPath = '/equateur/Company/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqcompanyPaginationModelResponseModel> {

    return this.equateurCompanyAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyPaginationModelResponseModel>) => r.body as EqcompanyPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqcompanyPaginationModelResponseModel> {

    return this.equateurCompanyAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyPaginationModelResponseModel>) => r.body as EqcompanyPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyAllPost
   */
  static readonly EquateurCompanyAllPostPath = '/equateur/Company/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllPost$Plain$Response(params?: {
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<CompanyResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllPostPath, 'post');
    if (params) {
      rb.query('offset', params.offset, {"style":"form"});
      rb.query('limit', params.limit, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllPost$Plain(params?: {
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<CompanyResponsiveModelListResponseModel> {

    return this.equateurCompanyAllPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyResponsiveModelListResponseModel>) => r.body as CompanyResponsiveModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllPost$Json$Response(params?: {
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<CompanyResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllPostPath, 'post');
    if (params) {
      rb.query('offset', params.offset, {"style":"form"});
      rb.query('limit', params.limit, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllPost$Json(params?: {
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<CompanyResponsiveModelListResponseModel> {

    return this.equateurCompanyAllPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyResponsiveModelListResponseModel>) => r.body as CompanyResponsiveModelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyAllWarehouseCompaniesWarehouseIdGet
   */
  static readonly EquateurCompanyAllWarehouseCompaniesWarehouseIdGetPath = '/equateur/Company/AllWarehouseCompanies/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Plain$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseCompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllWarehouseCompaniesWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseCompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Plain(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqwarehouseCompanyListResponseModel> {

    return this.equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseCompanyListResponseModel>) => r.body as EqwarehouseCompanyListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Json$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqwarehouseCompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllWarehouseCompaniesWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqwarehouseCompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Json(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqwarehouseCompanyListResponseModel> {

    return this.equateurCompanyAllWarehouseCompaniesWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqwarehouseCompanyListResponseModel>) => r.body as EqwarehouseCompanyListResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyByIdIdGet
   */
  static readonly EquateurCompanyByIdIdGetPath = '/equateur/Company/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyByNameNameGet
   */
  static readonly EquateurCompanyByNameNameGetPath = '/equateur/Company/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyByCityNameGet
   */
  static readonly EquateurCompanyByCityNameGetPath = '/equateur/Company/ByCity/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByCityNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCityNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByCityNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByCityNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCityNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqcompanyListResponseModel> {

    return this.equateurCompanyByCityNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyListResponseModel>) => r.body as EqcompanyListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByCityNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCityNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByCityNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByCityNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCityNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqcompanyListResponseModel> {

    return this.equateurCompanyByCityNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyListResponseModel>) => r.body as EqcompanyListResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyByCountryNameGet
   */
  static readonly EquateurCompanyByCountryNameGetPath = '/equateur/Company/ByCountry/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByCountryNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCountryNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByCountryNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByCountryNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCountryNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqcompanyListResponseModel> {

    return this.equateurCompanyByCountryNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyListResponseModel>) => r.body as EqcompanyListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByCountryNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCountryNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByCountryNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByCountryNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByCountryNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqcompanyListResponseModel> {

    return this.equateurCompanyByCountryNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyListResponseModel>) => r.body as EqcompanyListResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyByWarehouseIdGet
   */
  static readonly EquateurCompanyByWarehouseIdGetPath = '/equateur/Company/ByWarehouse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByWarehouseIdGet$Plain$Response(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByWarehouseIdGet$Plain(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqcompanyPaginationModelResponseModel> {

    return this.equateurCompanyByWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyPaginationModelResponseModel>) => r.body as EqcompanyPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyByWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByWarehouseIdGet$Json$Response(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqcompanyPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyByWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyByWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurCompanyByWarehouseIdGet$Json(params: {
    id: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqcompanyPaginationModelResponseModel> {

    return this.equateurCompanyByWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyPaginationModelResponseModel>) => r.body as EqcompanyPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyCreatePost
   */
  static readonly EquateurCompanyCreatePostPath = '/equateur/Company/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: CompanyCreateModel
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyCreatePost$Plain(params?: {
    context?: HttpContext
    body?: CompanyCreateModel
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: CompanyCreateModel
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyCreatePost$Json(params?: {
    context?: HttpContext
    body?: CompanyCreateModel
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyWarehousesPost
   */
  static readonly EquateurCompanyWarehousesPostPath = '/equateur/Company/Warehouses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyWarehousesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPost$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyWarehousesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyWarehousesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPost$Plain(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<CreateCompanyWarehousesResponseResponseModel> {

    return this.equateurCompanyWarehousesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>) => r.body as CreateCompanyWarehousesResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyWarehousesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPost$Json$Response(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyWarehousesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyWarehousesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPost$Json(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<CreateCompanyWarehousesResponseResponseModel> {

    return this.equateurCompanyWarehousesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>) => r.body as CreateCompanyWarehousesResponseResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyWarehousesPatch
   */
  static readonly EquateurCompanyWarehousesPatchPath = '/equateur/Company/Warehouses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyWarehousesPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPatch$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyWarehousesPatchPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyWarehousesPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPatch$Plain(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<CreateCompanyWarehousesResponseResponseModel> {

    return this.equateurCompanyWarehousesPatch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>) => r.body as CreateCompanyWarehousesResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyWarehousesPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPatch$Json$Response(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyWarehousesPatchPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyWarehousesPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyWarehousesPatch$Json(params?: {
    context?: HttpContext
    body?: CreateCompanyWarehousesRequest
  }
): Observable<CreateCompanyWarehousesResponseResponseModel> {

    return this.equateurCompanyWarehousesPatch$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCompanyWarehousesResponseResponseModel>) => r.body as CreateCompanyWarehousesResponseResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyUpdateIdPut
   */
  static readonly EquateurCompanyUpdateIdPutPath = '/equateur/Company/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: CompanyUpdateModel
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: CompanyUpdateModel
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: CompanyUpdateModel
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: CompanyUpdateModel
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyDeleteIdDelete
   */
  static readonly EquateurCompanyDeleteIdDeletePath = '/equateur/Company/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqcompanyResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqcompanyResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqcompanyResponseModel> {

    return this.equateurCompanyDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqcompanyResponseModel>) => r.body as EqcompanyResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost
   */
  static readonly EquateurCompanyAllClientWarehouseCompanyUserIdUserRolePostPath = '/equateur/Company/AllClientWarehouseCompany/{userId}/{userRole}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Plain$Response(params: {
    userId: string;
    userRole: string;
    companyCodeName?: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<ClientResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllClientWarehouseCompanyUserIdUserRolePostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {"style":"simple"});
      rb.path('userRole', params.userRole, {"style":"simple"});
      rb.query('companyCodeName', params.companyCodeName, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Plain(params: {
    userId: string;
    userRole: string;
    companyCodeName?: string;
    context?: HttpContext
    body?: string
  }
): Observable<ClientResponsiveModelListResponseModel> {

    return this.equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClientResponsiveModelListResponseModel>) => r.body as ClientResponsiveModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Json$Response(params: {
    userId: string;
    userRole: string;
    companyCodeName?: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<ClientResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllClientWarehouseCompanyUserIdUserRolePostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {"style":"simple"});
      rb.path('userRole', params.userRole, {"style":"simple"});
      rb.query('companyCodeName', params.companyCodeName, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Json(params: {
    userId: string;
    userRole: string;
    companyCodeName?: string;
    context?: HttpContext
    body?: string
  }
): Observable<ClientResponsiveModelListResponseModel> {

    return this.equateurCompanyAllClientWarehouseCompanyUserIdUserRolePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientResponsiveModelListResponseModel>) => r.body as ClientResponsiveModelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyClientsCompanyPost
   */
  static readonly EquateurCompanyClientsCompanyPostPath = '/equateur/Company/ClientsCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyClientsCompanyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyClientsCompanyPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
    body?: GetClientListCreateModel
  }
): Observable<StrictHttpResponse<ClientResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyClientsCompanyPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {"style":"form"});
      rb.query('limit', params.limit, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyClientsCompanyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyClientsCompanyPost$Plain(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
    body?: GetClientListCreateModel
  }
): Observable<ClientResponsiveModelListResponseModel> {

    return this.equateurCompanyClientsCompanyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClientResponsiveModelListResponseModel>) => r.body as ClientResponsiveModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyClientsCompanyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyClientsCompanyPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
    body?: GetClientListCreateModel
  }
): Observable<StrictHttpResponse<ClientResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyClientsCompanyPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {"style":"form"});
      rb.query('limit', params.limit, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyClientsCompanyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyClientsCompanyPost$Json(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
    body?: GetClientListCreateModel
  }
): Observable<ClientResponsiveModelListResponseModel> {

    return this.equateurCompanyClientsCompanyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientResponsiveModelListResponseModel>) => r.body as ClientResponsiveModelListResponseModel)
    );
  }

  /**
   * Path part for operation equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost
   */
  static readonly EquateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePostPath = '/equateur/Company/AllReferenceByClient/{userId}/{userRole}/{clientCodeName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Plain$Response(params: {
    userId: string;
    userRole: string;
    clientCodeName: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<ReferenceResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {"style":"simple"});
      rb.path('userRole', params.userRole, {"style":"simple"});
      rb.path('clientCodeName', params.clientCodeName, {"style":"simple"});
      rb.query('offset', params.offset, {"style":"form"});
      rb.query('limit', params.limit, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferenceResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Plain(params: {
    userId: string;
    userRole: string;
    clientCodeName: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<ReferenceResponsiveModelListResponseModel> {

    return this.equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReferenceResponsiveModelListResponseModel>) => r.body as ReferenceResponsiveModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Json$Response(params: {
    userId: string;
    userRole: string;
    clientCodeName: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<ReferenceResponsiveModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.EquateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {"style":"simple"});
      rb.path('userRole', params.userRole, {"style":"simple"});
      rb.path('clientCodeName', params.clientCodeName, {"style":"simple"});
      rb.query('offset', params.offset, {"style":"form"});
      rb.query('limit', params.limit, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferenceResponsiveModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Json(params: {
    userId: string;
    userRole: string;
    clientCodeName: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
    body?: string
  }
): Observable<ReferenceResponsiveModelListResponseModel> {

    return this.equateurCompanyAllReferenceByClientUserIdUserRoleClientCodeNamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReferenceResponsiveModelListResponseModel>) => r.body as ReferenceResponsiveModelListResponseModel)
    );
  }

}
