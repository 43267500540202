import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appResolver } from "@core/resolvers/app.resolver";

const routes: Routes = [
  { path: '', redirectTo: 'wh/configuration', pathMatch: 'full', resolve: { app: appResolver } },
  { path: 'wh/configuration', loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule), resolve: { app: appResolver } },
  { path: 'wh/page-not-found', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: 'wh/version', loadChildren: () => import('./pages/version/version.module').then(m => m.VersionModule) },
  { path: 'wh/refresh', loadChildren: () => import('./pages/refresh/refresh.module').then(m => m.RefreshModule) },
  { path: 'wh/**', redirectTo: 'page-not-found', pathMatch: 'full', }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
