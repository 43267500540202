/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StringResponseModel } from '../models/string-response-model';
import { UserResponsiveModelResponseModel } from '../models/user-responsive-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurUserMeTokenGet
   */
  static readonly EquateurUserMeTokenGetPath = '/equateur/User/Me/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurUserMeTokenGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserMeTokenGet$Plain$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserResponsiveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.EquateurUserMeTokenGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponsiveModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurUserMeTokenGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserMeTokenGet$Plain(params: {
    token: string;
    context?: HttpContext
  }
): Observable<UserResponsiveModelResponseModel> {

    return this.equateurUserMeTokenGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserResponsiveModelResponseModel>) => r.body as UserResponsiveModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurUserMeTokenGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserMeTokenGet$Json$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserResponsiveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.EquateurUserMeTokenGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponsiveModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurUserMeTokenGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserMeTokenGet$Json(params: {
    token: string;
    context?: HttpContext
  }
): Observable<UserResponsiveModelResponseModel> {

    return this.equateurUserMeTokenGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserResponsiveModelResponseModel>) => r.body as UserResponsiveModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurUserRefreshTokenTokenGet
   */
  static readonly EquateurUserRefreshTokenTokenGetPath = '/equateur/User/RefreshToken/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurUserRefreshTokenTokenGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserRefreshTokenTokenGet$Plain$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.EquateurUserRefreshTokenTokenGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurUserRefreshTokenTokenGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserRefreshTokenTokenGet$Plain(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.equateurUserRefreshTokenTokenGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurUserRefreshTokenTokenGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserRefreshTokenTokenGet$Json$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.EquateurUserRefreshTokenTokenGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurUserRefreshTokenTokenGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurUserRefreshTokenTokenGet$Json(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.equateurUserRefreshTokenTokenGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

}
