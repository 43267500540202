/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { ElementCreateAndCopyModel } from '../models/element-create-and-copy-model';
import { ElementCreateModel } from '../models/element-create-model';
import { ElementModelForRenderListResponseModel } from '../models/element-model-for-render-list-response-model';
import { ElementUpdateDataModel } from '../models/element-update-data-model';
import { ElementUpdateModel } from '../models/element-update-model';
import { EqelementClientListResponseModel } from '../models/eqelement-client-list-response-model';
import { EqelementCompanyListResponseModel } from '../models/eqelement-company-list-response-model';
import { EqelementListResponseModel } from '../models/eqelement-list-response-model';
import { EqelementPaginationModelResponseModel } from '../models/eqelement-pagination-model-response-model';
import { EqelementResponseModel } from '../models/eqelement-response-model';
import { GuidListResponseModel } from '../models/guid-list-response-model';

@Injectable({
  providedIn: 'root',
})
export class ElementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurElementPagePLGet
   */
  static readonly EquateurElementPagePLGetPath = '/equateur/Element/Page/{p}/{l}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementPagePLGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementPagePLGet$Plain$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementPagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementPagePLGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementPagePLGet$Plain(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementPagePLGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementPagePLGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementPagePLGet$Json$Response(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementPagePLGetPath, 'get');
    if (params) {
      rb.path('p', params['p'], {"style":"simple"});
      rb.path('l', params['l'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementPagePLGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementPagePLGet$Json(params: {
    'p': number;
    'l': number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementPagePLGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementAllGet
   */
  static readonly EquateurElementAllGetPath = '/equateur/Element/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByIdIdGet
   */
  static readonly EquateurElementByIdIdGetPath = '/equateur/Element/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByIdIdGet$Plain$Response(params: {
    id: string;
    isGetZone?: boolean;
    isGetAllLevel?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('isGetZone', params.isGetZone, {"style":"form"});
      rb.query('isGetAllLevel', params.isGetAllLevel, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByIdIdGet$Plain(params: {
    id: string;
    isGetZone?: boolean;
    isGetAllLevel?: boolean;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByIdIdGet$Json$Response(params: {
    id: string;
    isGetZone?: boolean;
    isGetAllLevel?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('isGetZone', params.isGetZone, {"style":"form"});
      rb.query('isGetAllLevel', params.isGetAllLevel, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByIdIdGet$Json(params: {
    id: string;
    isGetZone?: boolean;
    isGetAllLevel?: boolean;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByNameNameGet
   */
  static readonly EquateurElementByNameNameGetPath = '/equateur/Element/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByZoneZoneIdGet
   */
  static readonly EquateurElementByZoneZoneIdGetPath = '/equateur/Element/ByZone/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByZoneZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByZoneZoneIdGet$Plain$Response(params: {
    zoneId: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByZoneZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByZoneZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByZoneZoneIdGet$Plain(params: {
    zoneId: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementByZoneZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByZoneZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByZoneZoneIdGet$Json$Response(params: {
    zoneId: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByZoneZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByZoneZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByZoneZoneIdGet$Json(params: {
    zoneId: string;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementByZoneZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByWarehouseWarehouseIdGet
   */
  static readonly EquateurElementByWarehouseWarehouseIdGetPath = '/equateur/Element/ByWarehouse/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByWarehouseWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseWarehouseIdGet$Plain$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByWarehouseWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByWarehouseWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseWarehouseIdGet$Plain(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementByWarehouseWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByWarehouseWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseWarehouseIdGet$Json$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByWarehouseWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByWarehouseWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseWarehouseIdGet$Json(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementByWarehouseWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByWarehouseForRenderWarehouseIdGet
   */
  static readonly EquateurElementByWarehouseForRenderWarehouseIdGetPath = '/equateur/Element/ByWarehouseForRender/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByWarehouseForRenderWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseForRenderWarehouseIdGet$Plain$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ElementModelForRenderListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByWarehouseForRenderWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElementModelForRenderListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByWarehouseForRenderWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseForRenderWarehouseIdGet$Plain(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<ElementModelForRenderListResponseModel> {

    return this.equateurElementByWarehouseForRenderWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ElementModelForRenderListResponseModel>) => r.body as ElementModelForRenderListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByWarehouseForRenderWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseForRenderWarehouseIdGet$Json$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ElementModelForRenderListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByWarehouseForRenderWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElementModelForRenderListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByWarehouseForRenderWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByWarehouseForRenderWarehouseIdGet$Json(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<ElementModelForRenderListResponseModel> {

    return this.equateurElementByWarehouseForRenderWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ElementModelForRenderListResponseModel>) => r.body as ElementModelForRenderListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByZonesPost
   */
  static readonly EquateurElementByZonesPostPath = '/equateur/Element/ByZones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByZonesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementByZonesPost$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByZonesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByZonesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementByZonesPost$Plain(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementByZonesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByZonesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementByZonesPost$Json$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByZonesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByZonesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementByZonesPost$Json(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementByZonesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByCoordinateZoneIdXYGet
   */
  static readonly EquateurElementByCoordinateZoneIdXYGetPath = '/equateur/Element/ByCoordinate/{zoneId}/{x}/{y}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByCoordinateZoneIdXYGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByCoordinateZoneIdXYGet$Plain$Response(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByCoordinateZoneIdXYGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('x', params['x'], {"style":"simple"});
      rb.path('y', params['y'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByCoordinateZoneIdXYGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByCoordinateZoneIdXYGet$Plain(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementByCoordinateZoneIdXYGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByCoordinateZoneIdXYGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByCoordinateZoneIdXYGet$Json$Response(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByCoordinateZoneIdXYGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('x', params['x'], {"style":"simple"});
      rb.path('y', params['y'], {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByCoordinateZoneIdXYGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByCoordinateZoneIdXYGet$Json(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementByCoordinateZoneIdXYGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByColumnZoneIdXGet
   */
  static readonly EquateurElementByColumnZoneIdXGetPath = '/equateur/Element/ByColumn/{zoneId}/{x}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByColumnZoneIdXGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByColumnZoneIdXGet$Plain$Response(params: {
    zoneId: string;
    'x': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByColumnZoneIdXGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('x', params['x'], {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByColumnZoneIdXGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByColumnZoneIdXGet$Plain(params: {
    zoneId: string;
    'x': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementByColumnZoneIdXGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByColumnZoneIdXGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByColumnZoneIdXGet$Json$Response(params: {
    zoneId: string;
    'x': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByColumnZoneIdXGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('x', params['x'], {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByColumnZoneIdXGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByColumnZoneIdXGet$Json(params: {
    zoneId: string;
    'x': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementByColumnZoneIdXGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementByLineZoneIdYGet
   */
  static readonly EquateurElementByLineZoneIdYGetPath = '/equateur/Element/ByLine/{zoneId}/{y}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByLineZoneIdYGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByLineZoneIdYGet$Plain$Response(params: {
    zoneId: string;
    'y': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByLineZoneIdYGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('y', params['y'], {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByLineZoneIdYGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByLineZoneIdYGet$Plain(params: {
    zoneId: string;
    'y': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementByLineZoneIdYGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementByLineZoneIdYGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByLineZoneIdYGet$Json$Response(params: {
    zoneId: string;
    'y': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementByLineZoneIdYGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('y', params['y'], {"style":"simple"});
      rb.query('p', params['p'], {"style":"form"});
      rb.query('l', params['l'], {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementByLineZoneIdYGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementByLineZoneIdYGet$Json(params: {
    zoneId: string;
    'y': number;
    'p'?: number;
    'l'?: number;
    context?: HttpContext
  }
): Observable<EqelementPaginationModelResponseModel> {

    return this.equateurElementByLineZoneIdYGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementPaginationModelResponseModel>) => r.body as EqelementPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithWallZoneIdGet
   */
  static readonly EquateurElementWithWallZoneIdGetPath = '/equateur/Element/WithWall/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithWallTopZoneIdGet
   */
  static readonly EquateurElementWithWallTopZoneIdGetPath = '/equateur/Element/WithWallTop/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallTopZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallTopZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallTopZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallTopZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallTopZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallTopZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallTopZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallTopZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallTopZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallTopZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallTopZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallTopZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithWallBottomZoneIdGet
   */
  static readonly EquateurElementWithWallBottomZoneIdGetPath = '/equateur/Element/WithWallBottom/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallBottomZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallBottomZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallBottomZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallBottomZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallBottomZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallBottomZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallBottomZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallBottomZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallBottomZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallBottomZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallBottomZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallBottomZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithWallLeftZoneIdGet
   */
  static readonly EquateurElementWithWallLeftZoneIdGetPath = '/equateur/Element/WithWallLeft/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallLeftZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallLeftZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallLeftZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallLeftZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallLeftZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallLeftZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallLeftZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallLeftZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallLeftZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallLeftZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallLeftZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallLeftZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithWallRightZoneIdGet
   */
  static readonly EquateurElementWithWallRightZoneIdGetPath = '/equateur/Element/WithWallRight/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallRightZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallRightZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallRightZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallRightZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallRightZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallRightZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallRightZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallRightZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallRightZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallRightZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallRightZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallRightZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithWallCornerZoneIdGet
   */
  static readonly EquateurElementWithWallCornerZoneIdGetPath = '/equateur/Element/WithWallCorner/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallCornerZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallCornerZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallCornerZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallCornerZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallCornerZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallCornerZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithWallCornerZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallCornerZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithWallCornerZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithWallCornerZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithWallCornerZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithWallCornerZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementWithAccessZoneIdGet
   */
  static readonly EquateurElementWithAccessZoneIdGetPath = '/equateur/Element/WithAccess/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithAccessZoneIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithAccessZoneIdGet$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithAccessZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithAccessZoneIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithAccessZoneIdGet$Plain(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithAccessZoneIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementWithAccessZoneIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithAccessZoneIdGet$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementWithAccessZoneIdGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementWithAccessZoneIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementWithAccessZoneIdGet$Json(params: {
    zoneId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementWithAccessZoneIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementCreatePost
   */
  static readonly EquateurElementCreatePostPath = '/equateur/Element/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: ElementCreateModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreatePost$Plain(params?: {
    context?: HttpContext
    body?: ElementCreateModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: ElementCreateModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreatePost$Json(params?: {
    context?: HttpContext
    body?: ElementCreateModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementUpdateIdPut
   */
  static readonly EquateurElementUpdateIdPutPath = '/equateur/Element/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementDeleteIdDelete
   */
  static readonly EquateurElementDeleteIdDeletePath = '/equateur/Element/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementsZoneIdDelete
   */
  static readonly EquateurElementsZoneIdDeletePath = '/equateur/elements/{zoneId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementsZoneIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementsZoneIdDelete$Plain$Response(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementsZoneIdDeletePath, 'delete');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementsZoneIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementsZoneIdDelete$Plain(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<GuidListResponseModel> {

    return this.equateurElementsZoneIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementsZoneIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementsZoneIdDelete$Json$Response(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementsZoneIdDeletePath, 'delete');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementsZoneIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementsZoneIdDelete$Json(params: {
    zoneId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<GuidListResponseModel> {

    return this.equateurElementsZoneIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementResetIdPut
   */
  static readonly EquateurElementResetIdPutPath = '/equateur/Element/Reset/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementResetIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementResetIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementResetIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementResetIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementResetIdPut$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementResetIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementResetIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementResetIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementResetIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementResetIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementResetIdPut$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementResetIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetAccessIdPut
   */
  static readonly EquateurElementSetAccessIdPutPath = '/equateur/Element/SetAccess/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetAccessIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetAccessIdPut$Plain$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetAccessIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetAccessIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetAccessIdPut$Plain(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetAccessIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetAccessIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetAccessIdPut$Json$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetAccessIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetAccessIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetAccessIdPut$Json(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetAccessIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetByCoordinateZoneIdXYPut
   */
  static readonly EquateurElementSetByCoordinateZoneIdXYPutPath = '/equateur/Element/SetByCoordinate/{zoneId}/{x}/{y}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetByCoordinateZoneIdXYPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetByCoordinateZoneIdXYPut$Plain$Response(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetByCoordinateZoneIdXYPutPath, 'put');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('x', params['x'], {"style":"simple"});
      rb.path('y', params['y'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetByCoordinateZoneIdXYPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetByCoordinateZoneIdXYPut$Plain(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetByCoordinateZoneIdXYPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetByCoordinateZoneIdXYPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetByCoordinateZoneIdXYPut$Json$Response(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetByCoordinateZoneIdXYPutPath, 'put');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('x', params['x'], {"style":"simple"});
      rb.path('y', params['y'], {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetByCoordinateZoneIdXYPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetByCoordinateZoneIdXYPut$Json(params: {
    zoneId: string;
    'x': number;
    'y': number;
    context?: HttpContext
    body?: ElementUpdateModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetByCoordinateZoneIdXYPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetWallIdPut
   */
  static readonly EquateurElementSetWallIdPutPath = '/equateur/Element/SetWall/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetWallIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetWallIdPut$Plain$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetWallIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetWallIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetWallIdPut$Plain(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetWallIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetWallIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetWallIdPut$Json$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetWallIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetWallIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetWallIdPut$Json(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetWallIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetDirectionIdPut
   */
  static readonly EquateurElementSetDirectionIdPutPath = '/equateur/Element/SetDirection/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetDirectionIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDirectionIdPut$Plain$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetDirectionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetDirectionIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDirectionIdPut$Plain(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetDirectionIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetDirectionIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDirectionIdPut$Json$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetDirectionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetDirectionIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDirectionIdPut$Json(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetDirectionIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetNameIdPut
   */
  static readonly EquateurElementSetNameIdPutPath = '/equateur/Element/SetName/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetNameIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetNameIdPut$Plain$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetNameIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetNameIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetNameIdPut$Plain(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetNameIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetNameIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetNameIdPut$Json$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetNameIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetNameIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetNameIdPut$Json(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetNameIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetDescriptionIdPut
   */
  static readonly EquateurElementSetDescriptionIdPutPath = '/equateur/Element/SetDescription/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetDescriptionIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDescriptionIdPut$Plain$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetDescriptionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetDescriptionIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDescriptionIdPut$Plain(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetDescriptionIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetDescriptionIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDescriptionIdPut$Json$Response(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetDescriptionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('val', params.val, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetDescriptionIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementSetDescriptionIdPut$Json(params: {
    id: string;
    val?: string;
    context?: HttpContext
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetDescriptionIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementSetDataIdPut
   */
  static readonly EquateurElementSetDataIdPutPath = '/equateur/Element/SetData/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetDataIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetDataIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateDataModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetDataIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetDataIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetDataIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateDataModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetDataIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementSetDataIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetDataIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateDataModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementSetDataIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementSetDataIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementSetDataIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ElementUpdateDataModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementSetDataIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementGetAllRackGet
   */
  static readonly EquateurElementGetAllRackGetPath = '/equateur/Element/GetAllRack';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetAllRackGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetAllRackGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetAllRackGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetAllRackGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetAllRackGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetAllRackGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetAllRackGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetAllRackGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementGetByTypePost
   */
  static readonly EquateurElementGetByTypePostPath = '/equateur/Element/GetByType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetByTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypePost$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetByTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetByTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypePost$Plain(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetByTypePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetByTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypePost$Json$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetByTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetByTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypePost$Json(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetByTypePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementGetAllRackInWarehouseWarehouseIdGet
   */
  static readonly EquateurElementGetAllRackInWarehouseWarehouseIdGetPath = '/equateur/Element/GetAllRackInWarehouse/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetAllRackInWarehouseWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackInWarehouseWarehouseIdGet$Plain$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetAllRackInWarehouseWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetAllRackInWarehouseWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackInWarehouseWarehouseIdGet$Plain(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetAllRackInWarehouseWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetAllRackInWarehouseWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackInWarehouseWarehouseIdGet$Json$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetAllRackInWarehouseWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetAllRackInWarehouseWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetAllRackInWarehouseWarehouseIdGet$Json(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetAllRackInWarehouseWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementGetByTypeInWarehouseWarehouseIdPost
   */
  static readonly EquateurElementGetByTypeInWarehouseWarehouseIdPostPath = '/equateur/Element/GetByTypeInWarehouse/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetByTypeInWarehouseWarehouseIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypeInWarehouseWarehouseIdPost$Plain$Response(params: {
    warehouseId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetByTypeInWarehouseWarehouseIdPostPath, 'post');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetByTypeInWarehouseWarehouseIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypeInWarehouseWarehouseIdPost$Plain(params: {
    warehouseId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetByTypeInWarehouseWarehouseIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetByTypeInWarehouseWarehouseIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypeInWarehouseWarehouseIdPost$Json$Response(params: {
    warehouseId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetByTypeInWarehouseWarehouseIdPostPath, 'post');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetByTypeInWarehouseWarehouseIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementGetByTypeInWarehouseWarehouseIdPost$Json(params: {
    warehouseId: string;
    context?: HttpContext
    body?: Array<string>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementGetByTypeInWarehouseWarehouseIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementCreateAndCopyPost
   */
  static readonly EquateurElementCreateAndCopyPostPath = '/equateur/Element/CreateAndCopy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementCreateAndCopyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ElementCreateAndCopyModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementCreateAndCopyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementCreateAndCopyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyPost$Plain(params?: {
    context?: HttpContext
    body?: ElementCreateAndCopyModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementCreateAndCopyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementCreateAndCopyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyPost$Json$Response(params?: {
    context?: HttpContext
    body?: ElementCreateAndCopyModel
  }
): Observable<StrictHttpResponse<EqelementResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementCreateAndCopyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementCreateAndCopyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyPost$Json(params?: {
    context?: HttpContext
    body?: ElementCreateAndCopyModel
  }
): Observable<EqelementResponseModel> {

    return this.equateurElementCreateAndCopyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementResponseModel>) => r.body as EqelementResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementCreateAndCopyListPost
   */
  static readonly EquateurElementCreateAndCopyListPostPath = '/equateur/Element/CreateAndCopyList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementCreateAndCopyListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyListPost$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<ElementCreateAndCopyModel>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementCreateAndCopyListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementCreateAndCopyListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyListPost$Plain(params?: {
    context?: HttpContext
    body?: Array<ElementCreateAndCopyModel>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementCreateAndCopyListPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementCreateAndCopyListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyListPost$Json$Response(params?: {
    context?: HttpContext
    body?: Array<ElementCreateAndCopyModel>
  }
): Observable<StrictHttpResponse<EqelementListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementCreateAndCopyListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementCreateAndCopyListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementCreateAndCopyListPost$Json(params?: {
    context?: HttpContext
    body?: Array<ElementCreateAndCopyModel>
  }
): Observable<EqelementListResponseModel> {

    return this.equateurElementCreateAndCopyListPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementListResponseModel>) => r.body as EqelementListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementIsExistCodeInZoneZoneIdSpanAisleGet
   */
  static readonly EquateurElementIsExistCodeInZoneZoneIdSpanAisleGetPath = '/equateur/Element/IsExistCodeInZone/{zoneId}/{span}/{aisle}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Plain$Response(params: {
    zoneId: string;
    span: string;
    aisle: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementIsExistCodeInZoneZoneIdSpanAisleGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('span', params.span, {"style":"simple"});
      rb.path('aisle', params.aisle, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Plain(params: {
    zoneId: string;
    span: string;
    aisle: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Json$Response(params: {
    zoneId: string;
    span: string;
    aisle: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementIsExistCodeInZoneZoneIdSpanAisleGetPath, 'get');
    if (params) {
      rb.path('zoneId', params.zoneId, {"style":"simple"});
      rb.path('span', params.span, {"style":"simple"});
      rb.path('aisle', params.aisle, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Json(params: {
    zoneId: string;
    span: string;
    aisle: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurElementIsExistCodeInZoneZoneIdSpanAisleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementGetClientsByElementIdElementIdGet
   */
  static readonly EquateurElementGetClientsByElementIdElementIdGetPath = '/equateur/Element/GetClientsByElementId/{elementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetClientsByElementIdElementIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetClientsByElementIdElementIdGet$Plain$Response(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementClientListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetClientsByElementIdElementIdGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementClientListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetClientsByElementIdElementIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetClientsByElementIdElementIdGet$Plain(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<EqelementClientListResponseModel> {

    return this.equateurElementGetClientsByElementIdElementIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementClientListResponseModel>) => r.body as EqelementClientListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetClientsByElementIdElementIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetClientsByElementIdElementIdGet$Json$Response(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementClientListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetClientsByElementIdElementIdGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementClientListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetClientsByElementIdElementIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetClientsByElementIdElementIdGet$Json(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<EqelementClientListResponseModel> {

    return this.equateurElementGetClientsByElementIdElementIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementClientListResponseModel>) => r.body as EqelementClientListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementGetCompaniesByElementIdElementIdGet
   */
  static readonly EquateurElementGetCompaniesByElementIdElementIdGetPath = '/equateur/Element/GetCompaniesByElementId/{elementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetCompaniesByElementIdElementIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetCompaniesByElementIdElementIdGet$Plain$Response(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementCompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetCompaniesByElementIdElementIdGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementCompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetCompaniesByElementIdElementIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetCompaniesByElementIdElementIdGet$Plain(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<EqelementCompanyListResponseModel> {

    return this.equateurElementGetCompaniesByElementIdElementIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementCompanyListResponseModel>) => r.body as EqelementCompanyListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementGetCompaniesByElementIdElementIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetCompaniesByElementIdElementIdGet$Json$Response(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementCompanyListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementService.EquateurElementGetCompaniesByElementIdElementIdGetPath, 'get');
    if (params) {
      rb.path('elementId', params.elementId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementCompanyListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementGetCompaniesByElementIdElementIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementGetCompaniesByElementIdElementIdGet$Json(params: {
    elementId: string;
    context?: HttpContext
  }
): Observable<EqelementCompanyListResponseModel> {

    return this.equateurElementGetCompaniesByElementIdElementIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementCompanyListResponseModel>) => r.body as EqelementCompanyListResponseModel)
    );
  }

}
