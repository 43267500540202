import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CompanyService, AppEnumService, UserService, ElementTypeService } from '@core/api/be/services';
import { COOKIE_KEY, goToPageLogin } from '@core/const';
import { LoadingService } from '@core/services/loading.service';
import { StoreService } from '@core/services/store.service';
import { CookieService } from 'ngx-cookie-service';
import { mergeMap, forkJoin, of, map, catchError, tap } from 'rxjs';

export const appResolver: ResolveFn<boolean> = async (route, state) => {
  const cookieService = inject(CookieService);
  const companyService = inject(CompanyService);
  const appEnumService = inject(AppEnumService);
  const userService = inject(UserService);
  const elementTypeService = inject(ElementTypeService);
  await new Promise((resole, reject) => getInitData(userService, companyService, appEnumService, elementTypeService)
    .subscribe((res) => {
      console.log(res);
      if (res != null) {
        StoreService.companies = res[0].data ?? [];
        StoreService.typeElements = res[1];
        StoreService.rackType = res[2];
        StoreService.rackType.forEach(rt => {
          StoreService.typeElements.push({ name: rt.name, value: rt.normalizedType });
        });
        resole(true);
      }
      else {
        refreshToken(userService).subscribe(res => {
          if (res.res == 0 && res.data) updateToken(res.data, cookieService);
          else {
            // cookieService.deleteAll();
            goToPageLogin();
            reject();
          }
        }, err => {
          // cookieService.deleteAll();
          goToPageLogin();
          reject();
        })
      }
    }));
  return true;
};

function updateToken(token: string, cookieService: CookieService) {
  StoreService.wmsToken = token;
  const expired = new Date();
  expired.setHours(expired.getHours() + 2); // expire time of token is two hours
  cookieService.set(COOKIE_KEY.KEY_TOKEN_COOKIE, token, expired);
  window.location.reload();
}

function refreshToken(userService: UserService) {
  const ssoRefreshToken = StoreService.refreshToken;
  return userService.equateurUserRefreshTokenTokenGet$Json({ token: ssoRefreshToken });
}

function getInitData(userService: UserService, companyService: CompanyService, appEnumService: AppEnumService, elementTypeService: ElementTypeService) {
  const ssoToken = StoreService.wmsToken;
  LoadingService.$(true);
  return userService.equateurUserMeTokenGet$Json({ token: ssoToken }).pipe(
    mergeMap((res) => {
      if (res.res == 0 && res.accessToken && res.data) {
        StoreService.user = res.data;
        StoreService.token = res.accessToken;
        return forkJoin([companyService.equateurCompanyAllPost$Json({ body: `"${ssoToken}"`, offset: 0, limit: 100000 }),
        getElementType(appEnumService), getRackType(elementTypeService)])
      }
      return of(null);
    }),
    catchError(err => of(null))
  );
}

function getElementType(service: AppEnumService) {
  return service.equateurEnumTypeElementPageGet$Json({ p: 1, l: 10 }).pipe(map(res => {
    if (res.res == 0) return res.data?.page ?? [];
    return [];
  }))
}

function getRackType(service: ElementTypeService) {
  return service.equateurElementTypeAllGet$Json().pipe(map(res => {
    if (res.res == 0) return res.data ?? [];
    return [];
  }))
}
