/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { ElementTypeCreateWithOutFileModel } from '../models/element-type-create-with-out-file-model';
import { ElementTypeUpdateModel } from '../models/element-type-update-model';
import { ElementTypeUpdateWithOutFileModel } from '../models/element-type-update-with-out-file-model';
import { EqelementTypeListResponseModel } from '../models/eqelement-type-list-response-model';
import { EqelementTypeResponseModel } from '../models/eqelement-type-response-model';

@Injectable({
  providedIn: 'root',
})
export class ElementTypeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation equateurElementTypeAllGet
   */
  static readonly EquateurElementTypeAllGetPath = '/equateur/ElementType/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeAllGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeAllGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqelementTypeListResponseModel> {

    return this.equateurElementTypeAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeListResponseModel>) => r.body as EqelementTypeListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeAllGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeAllGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqelementTypeListResponseModel> {

    return this.equateurElementTypeAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeListResponseModel>) => r.body as EqelementTypeListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeByWarehouseIdWarehouseIdGet
   */
  static readonly EquateurElementTypeByWarehouseIdWarehouseIdGetPath = '/equateur/ElementType/ByWarehouseId/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeByWarehouseIdWarehouseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByWarehouseIdWarehouseIdGet$Plain$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeByWarehouseIdWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeByWarehouseIdWarehouseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByWarehouseIdWarehouseIdGet$Plain(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqelementTypeListResponseModel> {

    return this.equateurElementTypeByWarehouseIdWarehouseIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeListResponseModel>) => r.body as EqelementTypeListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeByWarehouseIdWarehouseIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByWarehouseIdWarehouseIdGet$Json$Response(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeByWarehouseIdWarehouseIdGetPath, 'get');
    if (params) {
      rb.path('warehouseId', params.warehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeByWarehouseIdWarehouseIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByWarehouseIdWarehouseIdGet$Json(params: {
    warehouseId: string;
    context?: HttpContext
  }
): Observable<EqelementTypeListResponseModel> {

    return this.equateurElementTypeByWarehouseIdWarehouseIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeListResponseModel>) => r.body as EqelementTypeListResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeCreatePost
   */
  static readonly EquateurElementTypeCreatePostPath = '/equateur/ElementType/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCreatePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  equateurElementTypeCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: {
'Name'?: string;
'Type'?: string;
'Width'?: number;
'Height'?: number;
'Length'?: number;
'MaxWeight'?: number;
'IsLevel'?: boolean;
'IsCompartment'?: boolean;
'IsActive'?: boolean;
'Color'?: string;
'WMSToken'?: string;
'EQWarehouseId'?: string;
'File'?: Blob;
'Level'?: number;
'CompartmentNames'?: string;
}
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCreatePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  equateurElementTypeCreatePost$Plain(params?: {
    context?: HttpContext
    body?: {
'Name'?: string;
'Type'?: string;
'Width'?: number;
'Height'?: number;
'Length'?: number;
'MaxWeight'?: number;
'IsLevel'?: boolean;
'IsCompartment'?: boolean;
'IsActive'?: boolean;
'Color'?: string;
'WMSToken'?: string;
'EQWarehouseId'?: string;
'File'?: Blob;
'Level'?: number;
'CompartmentNames'?: string;
}
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCreatePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  equateurElementTypeCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: {
'Name'?: string;
'Type'?: string;
'Width'?: number;
'Height'?: number;
'Length'?: number;
'MaxWeight'?: number;
'IsLevel'?: boolean;
'IsCompartment'?: boolean;
'IsActive'?: boolean;
'Color'?: string;
'WMSToken'?: string;
'EQWarehouseId'?: string;
'File'?: Blob;
'Level'?: number;
'CompartmentNames'?: string;
}
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCreatePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  equateurElementTypeCreatePost$Json(params?: {
    context?: HttpContext
    body?: {
'Name'?: string;
'Type'?: string;
'Width'?: number;
'Height'?: number;
'Length'?: number;
'MaxWeight'?: number;
'IsLevel'?: boolean;
'IsCompartment'?: boolean;
'IsActive'?: boolean;
'Color'?: string;
'WMSToken'?: string;
'EQWarehouseId'?: string;
'File'?: Blob;
'Level'?: number;
'CompartmentNames'?: string;
}
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeCreateWithOutFilePost
   */
  static readonly EquateurElementTypeCreateWithOutFilePostPath = '/equateur/ElementType/CreateWithOutFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCreateWithOutFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeCreateWithOutFilePost$Plain$Response(params?: {
    context?: HttpContext
    body?: ElementTypeCreateWithOutFileModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCreateWithOutFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCreateWithOutFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeCreateWithOutFilePost$Plain(params?: {
    context?: HttpContext
    body?: ElementTypeCreateWithOutFileModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeCreateWithOutFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCreateWithOutFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeCreateWithOutFilePost$Json$Response(params?: {
    context?: HttpContext
    body?: ElementTypeCreateWithOutFileModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCreateWithOutFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCreateWithOutFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeCreateWithOutFilePost$Json(params?: {
    context?: HttpContext
    body?: ElementTypeCreateWithOutFileModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeCreateWithOutFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeUpdateIdPut
   */
  static readonly EquateurElementTypeUpdateIdPutPath = '/equateur/ElementType/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeByIdIdGet
   */
  static readonly EquateurElementTypeByIdIdGetPath = '/equateur/ElementType/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeUpdateWithOutFileIdPut
   */
  static readonly EquateurElementTypeUpdateWithOutFileIdPutPath = '/equateur/ElementType/UpdateWithOutFile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeUpdateWithOutFileIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateWithOutFileIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateWithOutFileModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeUpdateWithOutFileIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeUpdateWithOutFileIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateWithOutFileIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateWithOutFileModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeUpdateWithOutFileIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeUpdateWithOutFileIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateWithOutFileIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateWithOutFileModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeUpdateWithOutFileIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeUpdateWithOutFileIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeUpdateWithOutFileIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ElementTypeUpdateWithOutFileModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeUpdateWithOutFileIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeCheckByTypeEqwarehouseIdTypeGet
   */
  static readonly EquateurElementTypeCheckByTypeEqwarehouseIdTypeGetPath = '/equateur/ElementType/CheckByType/{eqwarehouseId}/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Plain$Response(params: {
    type: string;
    eqwarehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCheckByTypeEqwarehouseIdTypeGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {"style":"simple"});
      rb.path('eqwarehouseId', params.eqwarehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Plain(params: {
    type: string;
    eqwarehouseId: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Json$Response(params: {
    type: string;
    eqwarehouseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCheckByTypeEqwarehouseIdTypeGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {"style":"simple"});
      rb.path('eqwarehouseId', params.eqwarehouseId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Json(params: {
    type: string;
    eqwarehouseId: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.equateurElementTypeCheckByTypeEqwarehouseIdTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeDeleteIdDelete
   */
  static readonly EquateurElementTypeDeleteIdDeletePath = '/equateur/ElementType/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<StrictHttpResponse<EqelementTypeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  equateurElementTypeDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: DeleteInputModel
  }
): Observable<EqelementTypeResponseModel> {

    return this.equateurElementTypeDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeResponseModel>) => r.body as EqelementTypeResponseModel)
    );
  }

  /**
   * Path part for operation equateurElementTypeCreateRackTypeDefaultGet
   */
  static readonly EquateurElementTypeCreateRackTypeDefaultGetPath = '/equateur/ElementType/CreateRackTypeDefault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCreateRackTypeDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCreateRackTypeDefaultGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCreateRackTypeDefaultGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCreateRackTypeDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCreateRackTypeDefaultGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<EqelementTypeListResponseModel> {

    return this.equateurElementTypeCreateRackTypeDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeListResponseModel>) => r.body as EqelementTypeListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equateurElementTypeCreateRackTypeDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCreateRackTypeDefaultGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EqelementTypeListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ElementTypeService.EquateurElementTypeCreateRackTypeDefaultGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EqelementTypeListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `equateurElementTypeCreateRackTypeDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equateurElementTypeCreateRackTypeDefaultGet$Json(params?: {
    context?: HttpContext
  }
): Observable<EqelementTypeListResponseModel> {

    return this.equateurElementTypeCreateRackTypeDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EqelementTypeListResponseModel>) => r.body as EqelementTypeListResponseModel)
    );
  }

}
