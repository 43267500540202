import { Injectable } from '@angular/core';
import {
  CompanyResponsiveModel,
  ElementTypeEnum,
  Eqelement,
  EqelementType,
  Eqwarehouse,
  Eqzone,
  UserResponsiveModel,
} from '@core/api/be/models';
import { DEFAULT_VALUE, ITile } from '@core/const';
import { ICell2 } from '@core/edition/types';

export interface IServiceStore {
  [x: string]: {
    tiles: ITile[];
    cells: ICell2[];
    gridTiles: ITile[][];
    gridCells: ICell2[][];
  };
}

const store: IServiceStore = {};
(window as any)['store'] = store;

export interface ICountry {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  static rackType: EqelementType[] = [];
  static countries: ICountry[] = [];
  static companies: CompanyResponsiveModel[] = [];
  static typeElements: ElementTypeEnum[] = [];
  static token?: string;
  static wmsToken: string;
  static refreshToken: string;
  static user: UserResponsiveModel;

  static getTiles(warehouseId: string) {
    return store[warehouseId]?.tiles || [];
  }
  static getCells(warehouseId: string) {
    return store[warehouseId]?.cells || [];
  }
  static getTile(warehouseId: string, tile: ITile) {
    return store[warehouseId]?.gridTiles[tile.x]?.[tile.y];
  }
  static setTilesFromZone(
    warehouse: Eqwarehouse,
    zoneData: ITile[],
    zones: Eqzone[],
    els: Eqelement[],
    currentZoneId?: string
  ): ITile[] {
    const warehouseId = warehouse.id!;
    if (!store[warehouseId]) {
      store[warehouseId] = {
        tiles: [],
        cells: [],
        gridTiles: [],
        gridCells: [],
      };
    }
    const currentStore = store[warehouseId];
    zoneData.forEach((tile) => {
      if (!currentStore.gridTiles[tile.x]) {
        currentStore.gridTiles[tile.x] = [];
      }
      currentStore.gridTiles[tile.x][tile.y] = tile;
      currentStore.tiles.push(tile);
      // makeCellFromTile
      StoreService.makeCellFromTile(
        warehouseId,
        tile,
        -1,
        warehouse.tileSize ?? DEFAULT_VALUE.TILE,
        zones.find((z) => z.id === tile.zoneId),
        els.find((e) => e.id === tile.elId),
        currentZoneId
      );
    });
    return currentStore.tiles;
  }

  static pushTile(
    warehouse: Eqwarehouse,
    tile: ITile,
    zones: Eqzone[],
    els: Eqelement[],
    currentZoneId?: string
  ) {
    const warehouseId = warehouse.id!;
    if (store[warehouseId]) {
      const currentStore = store[warehouseId];
      if (!currentStore.gridTiles[tile.x]) {
        currentStore.gridTiles[tile.x] = [];
      }
      currentStore.gridTiles[tile.x][tile.y] = tile;
      currentStore.tiles.push(tile);
      // makeCellFromTile
      const cell = StoreService.makeCellFromTile(
        warehouseId,
        tile,
        -1,
        warehouse.tileSize ?? DEFAULT_VALUE.TILE,
        zones.find((z) => z.id === tile.zoneId),
        els.find((e) => e.id === tile.elId),
        currentZoneId
      );
    }
  }

  static makeCellFromTile(
    warehouseId: string,
    tile: ITile,
    index: number,
    tileSize: number,
    zones?: Eqzone,
    el?: Eqelement,
    currentZoneId?: string
  ) {
    const cell = {
      x: tile.x,
      y: tile.y,
      data: {
        borderRack: tile.borderRack,
        isCurrentRender:
          tile.zoneId && tile.zoneId === currentZoneId ? undefined : false,
        color: tile.background,
        road: tile.road,
        size: tileSize! / DEFAULT_VALUE.UNIT,
        type: tile.type,
        wall: tile.wall,
        door: tile.door,
        zone: zones,
        el: el,
      },
    };
    const currentStore = store[warehouseId];
    if (index < 0) {
      if (!currentStore.gridCells[cell.x]) {
        currentStore.gridCells[cell.x] = [];
      }
      currentStore.gridCells[cell.x][cell.y] = cell;
      currentStore.cells.push(cell);
    } else {
      // assume that this case always correct
      const gridCell = currentStore.gridCells[cell.x][cell.y];
      gridCell.data = cell.data;
    }
    return cell;
  }

  constructor() { }
}
